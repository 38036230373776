import axios from "axios";
import { useEffect, useState } from "react";

import { GenericException } from "base/ui/errors";
import { LoadingView, NotFoundView } from "base/ui/status";

import { getTemplateBody, showCreateTemplatePopUp } from "./BroadcastHelper";
import { WAPreview } from "./WAPreview";

function SelectWAPreview({ template, setNewTemplate, onClose }) {
  const template_body = getTemplateBody(template);
  return (
    <div
      className="tw-flex-1 tw-cursor-pointer"
      aria-hidden={true}
      onClick={() => {
        if (template.status?.toLowerCase() === "approved") {
          onClose && onClose();
          setNewTemplate(template);
        }
      }}
    >
      <WAPreview
        files={template_body.image ? [template_body.image] : undefined}
        title={template.name}
        text={template_body.body}
        footer_text={template_body.footer}
        button_payloads={template_body.buttons}
        category={template.category}
        show_button_type={false}
        status={template.status}
      />
    </div>
  );
}

const TemplatesPopUp = ({ wa_business_number, org_id, onTemplateSelected, onCreateTemplate, onShopNotification }) => {
  const [templates, setTemplates] = useState(null);
  const [is_loading, setIsLoading] = useState();

  useEffect(() => {
    if (!templates) {
      getWaTemplates();
    }
  }, []);

  function getWaTemplates() {
    setIsLoading(true);
    axios
      .post("/api/admin/phone_number/templates", {
        wa_business_number: wa_business_number,
      })
      .then((resp) => {
        if (resp.data.errors) {
          GenericException.showPopup(resp.data.errors, "Error");
          return;
        }
        setTemplates(resp.data.waba_templates);
      })
      .finally(() => setIsLoading(false));
  }

  if (is_loading) return <LoadingView height={200} />;
  return (
    <div className="tw-grid tw-text-sm tw-p-4">
      <div className="tw-flex tw-gap-4 tw-justify-self-end tw-mb-4">
        {onShopNotification ? (
          <div>
            <button type="button" className="btn-primary-v2 tw-py-2.5" onClick={() => {
              onShopNotification()
            }}>Shop Notification</button>
          </div>
        ) : null}
        <button type="button" className="btn-secondary" onClick={() => {
          showCreateTemplatePopUp(onCreateTemplate)
        }}>Create Template</button>
      </div>
      <div className="">
        {templates ? (
          <div className="tw-grid sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-4 tw-justify-items-center">
            {templates.map((template) => (
              <SelectWAPreview
                key={template.name}
                template={template}
                setNewTemplate={(template) => {
                  onTemplateSelected(template);
                }}
              />
            ))}
          </div>
        ) : (
          <NotFoundView title="Oops! No templates found" />
        )}
      </div>

    </div>
  );
};

export default TemplatesPopUp ;
