import axios from 'axios';
import React, { useRef, useState } from 'react';
import { FileUploader } from 'base/file_uploader';
import { GenericException } from 'base/ui/errors';
import { Popup } from 'base/ui/popups';
import { LoadingOverlay } from 'base/ui/status';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sanitizeToId } from 'base/utils/common';
import { WAPreview } from './WAPreview';
import { FormErrorText } from '../components/ui/commonUI';
import SingleSelectionTab from '../components/ui/SingleSelectionTab';

const BUTTON_TYPES = {
  QUICK_REPLY: 'QUICK_REPLY',
  URL: 'URL'
}

function ButtonPayload({ button, onDelete, setErrors }) {
  const [type, setType] = useState(button.type || '');

  const onTypeChange = (evt) => {
    setType(evt.target.value)
    button.type = evt.target.value;
  }

  return (
    <div className='tw-mb-2 tw-p-2 tw-bg-primary-fade tw-border tw-rounded tw-relative'>
      <div className='tw-flex tw-flex-wrap tw-gap-4 tw-items-center'>
        <div className='tw-text-black tw-font-bold tw-text-sm'>
          <div className='tw-text-xxs tw-text-gray-500 tw-mb-1'>Button Text<span className='tw-text-red-600 tw-text-sm tw-leading-4'>*</span></div>
          <input className="tw-text-sm tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded tw-max-w-[175px]"
            maxLength={25} type='text' placeholder='Button Text' defaultValue={button.text} onChange={(evt) => {
              button.text = evt.target.value
              setErrors(null)
            }}
          />
        </div>
        <div className='tw-max-w-[120px]'>
          <div className='tw-text-xxs tw-text-gray-500 tw-mb-1'>Button Type</div>
          <select className='tw-outline-0 tw-border tw-border-gray-300 tw-p-2 tw-radius tw-text-sm tw-rounded tw-max-w-full' defaultValue={button.type} onChange={onTypeChange} >
            <option value={BUTTON_TYPES.URL}>URL</option>
            <option value={BUTTON_TYPES.QUICK_REPLY}>Quick Reply</option>
          </select>
        </div>
        {
          button.type === BUTTON_TYPES.URL && button.url?.includes('{{')
            ? <div className='tw-grow'>
              <div className='tw-text-xxs tw-text-gray-500 tw-mb-1'>Button URL <span className='tw-text-red-600 tw-text-sm tw-leading-4'>*</span></div>
              <span className='tw-text-xs'>{decodeURIComponent(button.url)}</span>
              <input
                className="tw-text-sm tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded tw-w-full"
                placeholder="Enter URL suffix - {{1}}"
                defaultValue={button.payload}
                onChange={(evt) => button.payload = evt.target.value}
              />
            </div>
            : button.type === BUTTON_TYPES.URL
              ? <div className='tw-grow'>
                <div className='tw-text-xxs tw-text-gray-500 tw-mb-1'>Button URL
                  <span className='tw-text-red-600 tw-text-sm tw-leading-4'>*</span>
                  <span className='tw-text-xxs tw-ml-2'>Ex: https://www.yourdomain.com/</span>
                </div>
                <input
                  className="tw-text-sm tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded tw-w-full"
                  placeholder="Enter URL here"
                  onChange={(evt) => {
                    button.payload = evt.target.value
                    setErrors(null)
                  }}
                />
              </div>
              : null
        }
        <FontAwesomeIcon onClick={onDelete} icon={faTrashAlt} className='tw-text-sm tw-p-1 tw-text-red-700 tw-absolute tw-top-1 tw-right-1' />
      </div>
    </div>
  )

}

function CreateButtons({ ctx, errors, setErrors }) {
  const [buttons, setButtons] = useState(ctx.button_payloads || []);

  const addButton = () => {
    const button_payloads = [...buttons, {
      'text': '', type: BUTTON_TYPES.QUICK_REPLY, payload: ''
    }]
    setButtons(button_payloads)
    ctx.button_payloads = button_payloads;
  }

  const deleteButton = (i) => {
    const button_payloads = [...buttons];
    button_payloads.splice(i, 1);
    setButtons(button_payloads)
    ctx.button_payloads = button_payloads;
  }

  return (
    <>
      <div className="tw-text-xs tw-text-gray-500 tw-mb-1 tw-mt-3" >
        Button Payloads:
      </div>
      {errors?.button_title ? <FormErrorText msg="Please add valid button text" /> : null}
      {errors?.button_url ? <FormErrorText msg={errors.msg} /> : null}
      {
        buttons?.map((button, i) => {
          return <div key={button.text + i} className='tw-mb-1' >
            <ButtonPayload button={button} onDelete={() => deleteButton(i)} setErrors={setErrors} />
          </div>
        })
      }
      {buttons.length < 3 ? <button className='tw-bg-yellow-500 tw-text-xs tw-rounded tw-px-2 tw-py-2' onClick={addButton}>+ Add Button</button> : null}
    </>
  )
}

const buttons = (button_payloads) => {
  const wa_button_payloads = []
  button_payloads?.forEach(btn => {
    if (btn.type === BUTTON_TYPES.URL) {
      wa_button_payloads.push({ "type": btn.type, "text": btn.text, "url": btn.payload })
    } else {
      wa_button_payloads.push({ "type": btn.type, "text": btn.text })
    }
  })
  return wa_button_payloads
};

const getBodyVariables = (text) => {
  return text.match(/\{\{([1-9]|[0-9][0-9])\}\}/g)
}
function CreateWaTemplate({ wa_business_number, template, onClose }) {
  const [files, setFiles] = useState(null);
  const [is_loading, setIsLoading] = useState(false);
  const ctx = useRef(template || {}).current;
  const [name, setName] = useState("");
  const [header_text, setHeaderText] = useState("");
  const [text, setText] = useState(template.text || "");
  const [footer_text, setFooterText] = useState(template.footer || "");
  const [errors, setErrors] = useState(null)
  const [header_type, setHeaderType] = useState()

  function validateAndSubmit() {
    if (!name?.length) {
      setErrors({ "name": true })
      return
    }
    if (header_type === "TEXT" && !header_text) {
      setErrors({ "header_text": true })
      return
    }
    if (!text?.length) {
      setErrors({ "text": true })
      return
    }
    if (getBodyVariables(text)) {
      getBodyVariables(text).map(bodyVariables => {
        if (!ctx[bodyVariables]) {
          setErrors({ "body_variables": true })
        }
      })
    }
    let button_error = false
    ctx.button_payloads?.forEach(btn => {
      const regex = /^[0-9a-zA-Z ]+$/; //button text to allow alphanumeric 
      if (!btn.text || !btn.text.match(regex)) {
        button_error = true
        setErrors({ "button_title": true })
        return
      }
      if (btn.type === BUTTON_TYPES.URL) {
        if (btn.payload.startsWith("https://wa.me")) {
          button_error = true
          setErrors({ "button_url": true, "msg": "Direct links to WhatsApp aren't allowed for buttons" })
          return
        }
      }
    })
    if (!button_error) {
      if (!window.confirm(`Are you sure to submit this new template "${name}"?`)) {
        return;
      }
      files?.length ? checkWAClientAndSubmit() : submitTemplate()
    }
  }

  function checkWAClientAndSubmit() {
    setIsLoading(true)
    axios.post(
      "/api/admin/get_wa_client",
      {
        "wa_business_number": wa_business_number,
      }
    ).then(
      (resp) => {
        if (resp.data.errors) {
          GenericException.showPopup(resp.data.errors, "Error");
          setIsLoading(false)
          return;
        }
        if (resp.data.wa_client === "d360")
          submitTemplate(files[0].url || files[0])
        else getFbMediaIdAndSubmit()
      }
    );
  }
  function getFbMediaIdAndSubmit() {
    setIsLoading(true)
    axios.post(
      "/api/admin/get_fb_media_id",
      {
        "wa_business_number": wa_business_number,
        "url": files[0].url || files[0],
      }
    ).then(
      (resp) => {
        if (resp.data.errors) {
          GenericException.showPopup(resp.data.errors, "Error");
          setIsLoading(false)
          return;
        }
        const fb_media_id = resp.data
        submitTemplate(fb_media_id)
      }
    );
  }

  function submitTemplate(fb_media_id) {
    const generated_template = {
      "language": "en",
      "name": name,
      "category": "MARKETING",
      "allow_category_change": true,
      "components": [
        {
          "type": "BODY",
          "text": text
        },
      ]
    }
    if (getBodyVariables(text)) {
      const body_text = []
      getBodyVariables(text).map(bodyVariables => {
        body_text.push(ctx[bodyVariables])
      })
      generated_template.components[0]["example"] = {
        "body_text": [body_text]
      }
    }
    if (footer_text) {
      generated_template.components.push({
        "type": "FOOTER",
        "text": footer_text
      })
    }
    if (header_type) {
      if (fb_media_id) { // MEDIA
        generated_template.components.push({
          "type": "HEADER",
          "format": header_type,
          "example": {
            "header_handle": [fb_media_id]
          }
        })
      } else { // TEXT
        generated_template.components.push({
          "type": "HEADER",
          "format": header_type,
          "text": header_text
        })
      }
    }

    if (ctx.button_payloads?.length) {
      generated_template.components.push({
        "type": "BUTTONS",
        "buttons": buttons(ctx.button_payloads)
      })
    }
    setIsLoading(true)
    axios.post(
      "/api/admin/fb_template",
      {
        "wa_business_number": wa_business_number,
        "template": generated_template
      }
    ).then(
      (resp) => {
        if (resp.data.errors) {
          GenericException.showPopup(resp.data.errors, "Error");
          return;
        }
        onClose(true)
      }
    ).finally(() => { setIsLoading(false) });
  }

  const showWAPreviewPopUp = (evt) => {
    let modified_text = text
    if (getBodyVariables(text)) {
      getBodyVariables(text).map(variable => {
        if (ctx[variable]) {
          modified_text = modified_text.replace(variable, ctx[variable])
        }
      })
    }
    var popup = Popup.showContextMenu(
      evt.target,
      <WAPreview
        files={files}
        text={modified_text}
        footer_text={footer_text}
        button_payloads={ctx.button_payloads}
        onSend={() => {
          popup.close();
        }}
      />
    );
  };

  const isFileUploadEnable = () => {
    return template?.files?.length > 0
  }

  return (
    <div className='md:tw-min-w-[75ch] tw-pb-[74px]'>
      {
        is_loading ? <LoadingOverlay title={"Publishing your template to WhatsApp..."} /> : null
      }
      <div className={`tw-grid tw-grid-cols-1 tw-p-2`} ref={(e) => { e ? ctx.grid_box = { width: e.offsetWidth, height: e.offsetHeight } : null }}>
        <div className='tw-px-2 tw-pt-2'>
          <div className="tw-text-sm tw-mb-2 tw-text-gray-900">
            Name <span className='tw-text-sm tw-leading-4 tw-text-red-600'>*</span>
          </div>
          <div className="tw-col-span-2 tw-border tw-rounded-md tw-p-2 tw-mb-1">
            <input
              placeholder="Please enter template name"
              className="tw-w-full tw-h-full tw-outline-none tw-text-sm"
              value={name}
              maxLength={60}
              onChange={(evt) => {
                const sanitized_name = sanitizeToId(evt.target.value).toLowerCase().trim()
                setName(sanitized_name)
                setErrors(null)
              }}
            />
          </div>
          {errors?.name ? <FormErrorText msg="Please enter unique template name" /> : null}
          {
            isFileUploadEnable() && <div className="tw-mb-1">
              <div className="tw-text-sm tw-my-2 tw-text-gray-900">
                Header <span className='tw-font-bold tw-text-black tw-text-xxs'>Optional</span>
              </div>
              <SingleSelectionTab values={{ "None": undefined, "Text": "TEXT", "Image": "IMAGE", "Video": "VIDEO", "Document": "DOCUMENT" }}
                setValue={(val) => { setHeaderType(val) }} />
              {
                header_type
                  ? <>
                    <div className='tw-text-xs tw-text-gray-400 tw-p-1 tw-my-1 tw-border tw-rounded-md tw-whitespace-pre-wrap'>
                      {"To help us review your content, provide examples of the variables or media in the header. "
                        + "Do not include any customer\ninformation. Cloud API hosted by Meta reviews "
                        + "templates and variable parameters to protect the security and\nintegrity of our services."}
                    </div>
                    {
                      header_type === "TEXT"
                        ? <div className="tw-text-sm tw-mb-2 tw-text-gray-900">
                          <div className="tw-col-span-2 tw-border tw-mt-2 tw-rounded-md tw-px-2 tw-pt-2">
                            <textarea
                              placeholder="Enter the header message here"
                              className="tw-w-full tw-outline-none tw-text-sm"
                              style={{ minHeight: "3rem" }}
                              value={header_text}
                              maxLength={60}
                              onChange={(evt) => {
                                setHeaderText(evt.target.value)
                                setErrors(null)
                              }}
                            />
                          </div>
                          {errors?.header_text ? <FormErrorText msg="Please enter header text or set header to 'None'" /> : null}
                          <span className='tw-float-right tw-text-xxs tw-text-gray tw-mt-2'>{header_text.length}/60</span>
                        </div>
                        : <FileUploader
                          className={"tw-mt-1"}
                          files={files}
                          allowed_mime_types={
                            header_type === "IMAGE"
                              ? ['image/jpeg', 'image/png', 'image/jpg']
                              : header_type === "VIDEO"
                                ? ['video/mp4']
                                : ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
                          }
                          onFilesUpdated={(files) => setFiles(files?.map(file => file.url))} />
                    }
                  </>
                  : null
              }
            </div>
          }

          <div className="tw-text-sm tw-my-2 tw-text-gray-900 tw-flex tw-justify-between">
            <div>Message <span className='tw-text-sm tw-leading-4 tw-text-red-600'>*</span></div>
            <button className='tw-bg-yellow-500 tw-text-xs tw-rounded tw-px-2 tw-py-2' onClick={() => {
              setText((text || '') + `{{${(getBodyVariables(text)?.length + 1) || 1}}}`)
            }}>
              Add Variable
            </button>
          </div>
          <div className="tw-col-span-2 tw-border tw-rounded-md tw-p-2">
            <textarea
              placeholder="Enter the body content here"
              className="tw-w-full tw-h-full tw-outline-none tw-text-sm"
              style={{ minHeight: "9rem" }}
              value={text}
              maxLength={1024}
              onChange={(evt) => {
                setText(evt.target.value)
                setErrors(null)
              }}
            />
          </div>
          {errors?.text ? <FormErrorText msg="Please enter template message" /> : null}
          <span className='tw-float-right tw-text-xxs tw-text-gray tw-mt-2'>{text.length}/1024</span>
        </div>
        {
          getBodyVariables(text)
            ? <div className="tw-text-sm tw-mb-4 tw-px-2 tw-text-gray-900">
              Samples for body content <span className='tw-text-sm tw-leading-4 tw-text-red-600'>*</span>
              {
                getBodyVariables(text)?.map((variable, i) => (
                  <div key={i} className='tw-flex tw-gap-2 tw-mt-2 tw-mx-2 tw-items-center'>
                    <p>{variable}</p>
                    <input
                      placeholder={`Enter content for ${variable}`}
                      className="tw-w-full tw-text-sm  tw-border tw-rounded-md tw-p-2"
                      maxLength={60}
                      onChange={(evt) => {
                        ctx[variable] = evt.target.value;
                        setErrors(null)
                      }}
                    />
                  </div>

                ))
              }
              {errors?.body_variables ? <FormErrorText msg="Please enter all samples" /> : null}
            </div>
            : null
        }
        <div className="tw-text-sm tw-mb-2 tw-px-2 tw-text-gray-900">
          Footer Message <span className='tw-font-bold tw-text-black tw-text-xxs'>Optional</span>
          <div className="tw-col-span-2 tw-border tw-mt-2 tw-rounded-md tw-px-2 tw-pt-2">
            <textarea
              placeholder="Enter the footer message"
              className="tw-w-full tw-outline-none tw-text-sm"
              style={{ minHeight: "3rem" }}
              value={footer_text}
              maxLength={60}
              onChange={(evt) => setFooterText(evt.target.value)}
            />
          </div>
          <span className='tw-float-right tw-text-xxs tw-text-gray tw-mt-2'>{footer_text.length}/60</span>
          {template.button_payloads ?
            <CreateButtons ctx={ctx} errors={errors} setErrors={setErrors} /> : null
          }
        </div>
      </div>
      <div className='tw-border-t tw-bg-white tw-p-4 tw-flex tw-gap-4 tw-items-center tw-justify-center md:tw-justify-end tw-absolute tw-bottom-0 tw-w-full'>
        {onClose && (
          <button
            className="tw-border tw-border-primary-dark tw-text-primary-dark tw-rounded-md tw-px-4 tw-py-2 tw-text-sm"
            onClick={() => onClose(false)}
          >
            Cancel
          </button>
        )}
        <button
          className="tw-border tw-border-primary-dark tw-text-primary-dark tw-rounded-md tw-px-4 tw-py-2 tw-text-sm"
          onClick={showWAPreviewPopUp}
        >
          Preview
        </button>
        <button className='tw-border tw-border-primary-dark tw-bg-primary-dark tw-text-white tw-rounded-md tw-px-4 tw-py-2 tw-text-sm'
          onClick={() => validateAndSubmit()}>
          {'Submit'}
        </button>
      </div>
    </div>
  );
}
export default CreateWaTemplate;
