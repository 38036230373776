import { Popup } from "base/ui/popups";

import CreateBroadcast from "./CreateBroadcast";
import TemplatesPopUp from "./TemplatesPopUp";
import { WAPreview } from "./WAPreview";

const STATUS_STYLE = {
	"CANCELLED": { "backgroundColor": "#FFE4E4", "color": "#950000" },
	"APPROVED": { "backgroundColor": "#15951A14", "color": "#14AA1A" },
}

const NOTIFICATION_TYPES = {
	PRODUCTS: "products",
	SHOP: "shop",
	TEMPLATE: "template",
};
/**
 * Extracts specific data from a template object and returns it in a structured format.
 *
 * @param {Object} template - The template object containing components.
 * @returns {Object} - An object containing extracted data from the template.
 * @property {string} [message.image] - The file URL for image, video, or document formats.
 * @property {string} [message.body] - The text content of the body component.
 * @property {string} [message.header_text] - The text content of the header component.
 * @property {string} [message.footer] - The text content of the footer component.
 * @property {Array} [message.buttons] - The buttons in the buttons component.
 */
function getTemplateBody(template) {
	if (!template)
		return null
	let message = {};
	template?.components.forEach((c) => {
		if (c.type.toLowerCase() === "header") {
			if (c.parameters) {
				c.parameters.forEach((p) => {
					if (!p) return;
					if (p.type === "image") {
						message.image = p.image.link;
					} else if (p.type === "video") {
						message.image = p.video.link;
					} else if (p.type === "document") {
						message.image = p.document.link;
					}
				});
			} else if (c.format === "IMAGE" || c.format === "VIDEO" || c.format === "DOCUMENT") {
				message.image = c.example?.header_handle?.[0];
			} else if (c.format === "TEXT") {
				message.header_text = c.text;
			}
		} else if (c.type.toLowerCase() === "body") {
			message.body = c.text;
		} else if (c.type.toLowerCase() === "footer") {
			message.footer = c.text;
		} else if (c.type.toLowerCase() === "buttons") {
			message.buttons = c.buttons;
		}
	});
	return message;
}

/**
 * Extracts variables from a given text string that follow the format "{{n}}" where n is a number.
 *
 * @function getBodyVariables
 * @param {string} text - The text string to extract variables from.
 * @returns {Array|undefined} - An array of extracted variables if found, or undefined if no variables are found.
 *
 * @example
 * getBodyVariables("Hello, {{1}}! Welcome to {{2}}.") // Returns: ["{{1}}", "{{2}}"]
 * getBodyVariables("No variables here.") // Returns: undefined
 */
function getBodyVariables(text) {
	if (!text) return undefined;
	return text.match(/\{\{([1-9]|[0-9][0-9])\}\}/g);
}

/**
 * Displays a popup to select a template for creating a broadcast.
 *
 * @function showSelectTemplatePopUp
 * @param {Object} org - The organization object containing the business number and ID.
 * @param {Array} selected_users_ui - An UI of selected users for the broadcast.
 * @param {Object} url_params - Additional URL parameters for the broadcast creation mostly search filters.
 *
 * @returns {undefined} - This function does not return a value.
 *
 * @example
 * showSelectTemplatePopUp(org, selected_users_ui, url_params);
 */
const showSelectTemplatePopUp = (org, selected_users_ui, url_params, onCreateTemplate) => {
	const popup = Popup.show(
		"Select Template",
		<TemplatesPopUp
			wa_business_number={org.wa_business_number}
			org_id={org._id}
			onTemplateSelected={(template) => {
				popup.close();
				showCreateBroadcastPopUp(org, template, selected_users_ui, url_params);
			}}
			onCreateTemplate={(sample_template) => {
				popup.close()
				onCreateTemplate && onCreateTemplate(sample_template)
			}}

		/>,
	);
};

/**
 * Displays a popup to create a broadcast using a selected template.
 *
 * @function showCreateBroadcastPopUp
 * @param {Object} org - The organization object containing the business number and ID.
 * @param {Object} template - The selected template object for creating the broadcast.
 * @param {Array} selected_users_ui - An UI of selected users for the broadcast.
 * @param {Object} url_params - Additional URL parameters for the broadcast creation mostly search filters.
 *
 * @returns {undefined} - This function does not return a value.
 *
 * @example
 * showCreateBroadcastPopUp(org, template, selected_users_ui, url_params);
 */
const showCreateBroadcastPopUp = (org, template, selected_users_ui, url_params, broadcast_type, DeliveryRoutesUI) => {
	const popup = Popup.show(
		"Create Broadcast",
		<CreateBroadcast
			org={org}
			template={template}
			selected_users_ui={selected_users_ui}
			url_params={url_params}
			DeliveryRoutesUI={DeliveryRoutesUI}
			onSubmit={() => {
				popup.close();
			}}
			broadcast_type={broadcast_type}
		/>,
	);
};

function SelectWAPreview({ template, setNewTemplate, onClose }) {
	return (
		<div className='tw-grid tw-mt-2 md:tw-max-w-[220px] tw-drop-shadow tw-cursor-pointer' onClick={() => {
			onClose()
			setNewTemplate(template)
		}}>
			<WAPreview files={template.files}
				title={template.title}
				text={template.text}
				footer_text={template.footer_text}
				button_payloads={template.button_payloads}
				show_button_type={true} />
		</div>
	);
}

const SampleTemplates = ({ setNewTemplate, onClose }) => {
	const img_url = "https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2024-04-08/1712590454562DWt5Z02__samplegallery.png"
	const templates = [
		{
			title: "Seasonal discounts",
			text: "Dont miss out on our amazing festival offer!\nClick now to shop and enjoy a fabulous 20% discount on all our products/services. \nHurry, limited time only!",
			footer: "Reply STOP to Unsubscribe",
			files: [{ url: img_url }],
			button_payloads: [{ text: "Shop Now", type: "QUICK_REPLY", payload: "Shop Now" }]
		},
		{
			title: "Festival Special",
			text: "Eid Mubarak, friends! Until Friday, enjoy Ksh 1,500 off with the code “Eid24”.\n\nDon’t miss out! Swing by one of our branches or shop online:",
			footer: "Sukhiba",
			files: [{ url: img_url }],
			button_payloads: [{ text: "View Shop", type: "URL", payload: "https://lite.sukhiba.com/" }, { text: "Pause", type: "QUICK_REPLY", payload: "Pause" }]
		},
		{
			title: "New Product Alert",
			text: "Introducing Gira Oil - Your Ultimate Health Companion! Discover the perfect blend of health and flavor with our latest creation",
			footer: "#GiraOil #HealthyCooking #NewProduct",
			files: [{ url: img_url }],
			button_payloads: [{ text: "View Shop", type: "URL", payload: "https://lite.sukhiba.com/" }]
		},
		{
			title: "Order Confirmation",
			text: "Hey, your order with Sukhiba is confirmed and will deliver within 24hrs, Thanks for shopping with us.\n\nHappy Shopping!!",
			footer: "Sukhiba",
		},
		{
			"title": "New Collection Alert",
			"text": "Discover our latest collection of summer wear! 🌞 Enjoy exclusive designs and vibrant colors to keep you cool and stylish.\n\nShop now and be the trendsetter this season!",
			"footer": "FashionHub",
			"files": [{ "url": img_url }],
			"button_payloads": [
				{ "text": "Shop Now", "type": "URL", "payload": "https://fashionhub.com/new-arrivals" },
				{ "text": "Not Interested", "type": "QUICK_REPLY", "payload": "Not Interested" }
			]
		},
		{
			"title": "Exclusive Offer",
			"text": "Limited Time Offer! 🎉 Get 25% off on your next purchase using code \"SALE25\". Offer valid until Sunday.\n\nHurry up and grab the deal!",
			"footer": "DealBuddy",
			"files": [{ "url": img_url }],
			"button_payloads": [
				{ "text": "Shop Now", "type": "URL", "payload": "https://dealbuddy.com/offers" },
				{ "text": "Unsubscribe", "type": "QUICK_REPLY", "payload": "Unsubscribe" }
			]
		},
		{
			"title": "You're Invited!",
			"text": "Join us for the Grand Opening of our new store! 🎉\n\n📅 Date: Jan 25, 2025\n📍 Location: Downtown Plaza\n\nExciting offers await you!",
			"footer": "CityStore",
			"files": [{ "url": img_url }],
			"button_payloads": [
				{ "text": "RSVP Now", "type": "URL", "payload": "https://citystore.com/rsvp" },
				{ "text": "Remind Me Later", "type": "QUICK_REPLY", "payload": "Remind Me Later" }
			]
		},
		{
			"title": "Exciting Launch",
			"text": "🚀 Say hello to our brand-new smartphone, packed with innovative features. Experience the future of technology today.\n\nAvailable for pre-order now!",
			"footer": "TechWorld",
			"files": [{ "url": img_url }],
			"button_payloads": [
				{ "text": "Pre-order", "type": "URL", "payload": "https://techworld.com/preorder" },
				{ "text": "More Details", "type": "QUICK_REPLY", "payload": "More Details" }
			]
		},
		{
			"title": "Friendly Reminder",
			"text": "Hi [Name], this is a reminder for your appointment with Dr. Smith.\n\n📅 Date: Jan 28, 2025\n⏰ Time: 3:00 PM\n\nSee you there!",
			"footer": "CareClinic",
			"button_payloads": [
				{ "text": "Confirm Appointment", "type": "QUICK_REPLY", "payload": "Confirm Appointment" },
				{ "text": "Reschedule", "type": "QUICK_REPLY", "payload": "Reschedule" }
			]
		},
		{
			"title": "We Value Your Feedback",
			"text": "Your opinion matters! Help us improve by completing this quick survey.\n\nIt will only take 2 minutes of your time.",
			"footer": "FeedbackPro",
			"button_payloads": [
				{ "text": "Take Survey", "type": "URL", "payload": "https://feedbackpro.com/survey" },
				{ "text": "Not Now", "type": "QUICK_REPLY", "payload": "Not Now" }
			]
		},
		{
			"title": "Happy Holidays!",
			"text": "Wishing you and your loved ones a joyous holiday season and a prosperous New Year! 🎄✨\n\nEnjoy 10% off with code \"HOLIDAY10\" until Dec 31st.",
			"footer": "HolidayMart",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Shop Holiday Offers", "type": "URL", "payload": "https://holidaymart.com/offers" },
				{ "text": "Pause Notifications", "type": "QUICK_REPLY", "payload": "Pause Notifications" }
			]
		},
		{
			"title": "Service Downtime Notice",
			"text": "Dear Customer, our services will be temporarily unavailable for maintenance:\n\n⏰ Date: Jan 30, 2025\n⏳ Time: 1:00 AM - 5:00 AM\n\nThank you for your understanding.",
			"footer": "ServiceTeam",
			"button_payloads": [
				{ "text": "Contact Support", "type": "URL", "payload": "https://serviceteam.com/support" },
				{ "text": "Pause Notifications", "type": "QUICK_REPLY", "payload": "Pause Notifications" }
			]
		},
		{
			"title": "Flash Sale is Here!",
			"text": "🎉 Flash Sale Alert! Enjoy up to 50% off on selected items for the next 24 hours.\n\nDon't miss out!",
			"footer": "BargainBazaar",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Shop Now", "type": "URL", "payload": "https://bargainbazaar.com/flash-sale" },
				{ "text": "Unsubscribe", "type": "QUICK_REPLY", "payload": "Unsubscribe" }
			]
		},
		{
			"title": "Thank You!",
			"text": "We appreciate your recent purchase with us. 💖\n\nYour satisfaction means the world to us. Let us know if there’s anything we can do for you!",
			"footer": "CustomerCare",
			"button_payloads": [
				{ "text": "Leave Feedback", "type": "URL", "payload": "https://customercare.com/feedback" },
				{ "text": "Contact Support", "type": "QUICK_REPLY", "payload": "Contact Support" }
			]
		},
		{
			"title": "Back in Stock!",
			"text": "Good news! 🎉 Your favorite item is back in stock. Grab it before it sells out again.\n\n🛒 Limited quantities available. Don't miss out!",
			"footer": "ShopEasy",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Shop Now", "type": "URL", "payload": "https://shopeasy.com/restock" },
				{ "text": "Notify Later", "type": "QUICK_REPLY", "payload": "Notify Later" }
			]
		},
		{
			"title": "Just for You!",
			"text": "Hi [Name], based on your recent purchases, we thought you might like these products:\n\n✨ [Product Name 1] \n✨ [Product Name 2]\n\nShop now to enjoy exclusive deals!",
			"footer": "YourStyle",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "View Recommendations", "type": "URL", "payload": "https://yourstyle.com/recommendations" },
				{ "text": "Not Interested", "type": "QUICK_REPLY", "payload": "Not Interested" }
			]
		},
		{
			"title": "Your Cart is Waiting!",
			"text": "Hi [Name], you left something in your cart! 🛒\n\nComplete your purchase today and get 10% off using code \"CART10\".",
			"footer": "QuickCart",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Complete Purchase", "type": "URL", "payload": "https://quickcart.com/checkout" },
				{ "text": "Remove Items", "type": "QUICK_REPLY", "payload": "Remove Items" }
			]
		},
		{
			"title": "Winter Collection 2025",
			"text": "❄️ Embrace the chill in style with our new Winter Collection. From cozy sweaters to chic boots, we’ve got it all.\n\nShop now and stay warm this season!",
			"footer": "TrendWear",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Shop Winter", "type": "URL", "payload": "https://trendwear.com/winter-collection" },
				{ "text": "View Later", "type": "QUICK_REPLY", "payload": "View Later" }
			]
		},
		{
			"title": "Hurry! Almost Gone",
			"text": "🚨 Limited Stock Alert! Your favorite [Product Name] is almost sold out. Order now to secure yours.\n\nFirst come, first served!",
			"footer": "ShopFast",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Order Now", "type": "URL", "payload": "https://shopfast.com/product/[product-id]" },
				{ "text": "Not Interested", "type": "QUICK_REPLY", "payload": "Not Interested" }
			]
		},
		{
			"title": "Today's Deal",
			"text": "🎉 Deal of the Day: Save 40% on [Product Name].\n\nOffer valid only for 24 hours. Don’t miss out!",
			"footer": "DailyDeals",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Shop Now", "type": "URL", "payload": "https://dailydeals.com/todays-deal" },
				{ "text": "Remind Me Tomorrow", "type": "QUICK_REPLY", "payload": "Remind Me Tomorrow" }
			]
		},
		{
			"title": "Loyalty Rewards Update",
			"text": "Hi [Name], you’ve earned 500 points in our loyalty program! 🎁\n\nRedeem your points for discounts or exclusive rewards.",
			"footer": "RewardPlus",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "View Rewards", "type": "URL", "payload": "https://rewardplus.com/rewards" },
				{ "text": "Not Now", "type": "QUICK_REPLY", "payload": "Not Now" }
			]
		},
		{
			"title": "Share Your Experience",
			"text": "Hi [Name], thank you for shopping with us! 💖\n\nWe’d love to hear your feedback on [Product Name]. Leave a review and help others discover the product.",
			"footer": "FeedbackHub",
			"button_payloads": [
				{ "text": "Write a Review", "type": "URL", "payload": "https://feedbackhub.com/review/[product-id]" },
				{ "text": "Not Now", "type": "QUICK_REPLY", "payload": "Not Now" }
			]
		},
		{
			"title": "Pre-Order Now!",
			"text": "🚀 Be the first to own our newest [Product Name]. Pre-order today and receive exclusive benefits.\n\nOffer ends Jan 31, 2025.",
			"footer": "TechNest",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Pre-Order Now", "type": "URL", "payload": "https://technest.com/preorder/[product-id]" },
				{ "text": "Learn More", "type": "QUICK_REPLY", "payload": "Learn More" }
			]
		},
		{
			"title": "Refer & Earn!",
			"text": "Invite your friends to shop with us and earn rewards! 🎁\n\nFor every friend who makes a purchase, you’ll get $10 in credits.",
			"footer": "ReferralClub",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Refer Now", "type": "URL", "payload": "https://referralclub.com/refer" },
				{ "text": "Not Interested", "type": "QUICK_REPLY", "payload": "Not Interested" }
			]
		},
		{
			"title": "VIP Sale Just for You",
			"text": "🎉 Hi [Name], as one of our VIP customers, you're invited to an exclusive early access sale! Enjoy up to 40% off before the public.\n\nShop now and stay ahead!",
			"footer": "LuxuryLane",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Access Sale", "type": "URL", "payload": "https://luxurylane.com/vip-sale" },
				{ "text": "No Thanks", "type": "QUICK_REPLY", "payload": "No Thanks" }
			]
		},
		{
			"title": "Free Shipping Alert!",
			"text": "📦 Get FREE shipping on all orders over $50! Shop now and enjoy your favorites delivered to your doorstep.\n\nOffer valid until Jan 31, 2025.",
			"footer": "ShopSmart",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Start Shopping", "type": "URL", "payload": "https://shopsmart.com/free-shipping" },
				{ "text": "Not Now", "type": "QUICK_REPLY", "payload": "Not Now" }
			]
		},
		{
			"title": "Join Our Giveaway!",
			"text": "🎁 Exciting news! Enter our giveaway and stand a chance to win a $100 gift card.\n\n📅 Deadline: Jan 31, 2025. It’s free to join!",
			"footer": "GiftZone",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Enter Now", "type": "URL", "payload": "https://giftzone.com/giveaway" },
				{ "text": "Learn More", "type": "QUICK_REPLY", "payload": "Learn More" }
			]
		},
		{
			"title": "BOGO Alert!",
			"text": "🎉 Buy 1, Get 1 FREE on all accessories! Mix and match your favorites and save big.\n\nOffer valid this weekend only.",
			"footer": "StyleDeals",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Shop BOGO", "type": "URL", "payload": "https://styledeals.com/bogo" },
				{ "text": "Unsubscribe", "type": "QUICK_REPLY", "payload": "Unsubscribe" }
			]
		},
		{
			"title": "Clearance Sale - Up to 70% Off!",
			"text": "🔥 Our end-of-season sale is here! Enjoy up to 70% off on selected items. Stock up before it’s gone!\n\nShop your favorites today.",
			"footer": "ClearanceHub",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Shop Now", "type": "URL", "payload": "https://clearancehub.com/sale" },
				{ "text": "Not Interested", "type": "QUICK_REPLY", "payload": "Not Interested" }
			]
		},
		{
			"title": "You’ve Earned a Reward!",
			"text": "🎉 Thank you for referring your friends! You’ve earned $15 in rewards. Use it on your next purchase and save big.\n\nKeep sharing to earn more!",
			"footer": "RewardZone",
			"button_payloads": [
				{ "text": "Use Rewards", "type": "URL", "payload": "https://rewardzone.com/rewards" },
				{ "text": "Learn More", "type": "QUICK_REPLY", "payload": "Learn More" }
			]
		},
		{
			"title": "We’re Expanding!",
			"text": "🎉 Great news! We’re opening a new store near you at [Location].\n\nVisit us during the grand opening week and enjoy exclusive discounts and giveaways.",
			"footer": "ShopNest",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Find Us", "type": "URL", "payload": "https://shopnest.com/stores" },
				{ "text": "Remind Me Later", "type": "QUICK_REPLY", "payload": "Remind Me Later" }
			]
		},
		{
			"title": "Go Green with Us!",
			"text": "🌱 Discover our eco-friendly collection! From reusable bottles to organic clothing, every purchase supports sustainability.\n\nShop green and make a difference!",
			"footer": "EcoShop",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Shop Now", "type": "URL", "payload": "https://ecoshop.com/green-products" },
				{ "text": "Pause Notifications", "type": "QUICK_REPLY", "payload": "Pause Notifications" }
			]
		},
		{
			"title": "We’ve Picked These for You",
			"text": "Hi [Name], we’ve noticed you love [Category/Product]! Check out these exclusive deals tailored just for you.\n\nHurry, these offers expire soon.",
			"footer": "TailorDeals",
			"button_payloads": [
				{ "text": "View Offers", "type": "URL", "payload": "https://tailordeals.com/offers" },
				{ "text": "Not Interested", "type": "QUICK_REPLY", "payload": "Not Interested" }
			]
		},
		{
			"title": "Your Wishlist is Waiting!",
			"text": "Hi [Name], the items in your wishlist are still available! Don’t wait too long – they’re selling fast.\n\nComplete your purchase today.",
			"footer": "WishCart",
			"files": [{"url": img_url}],
			"button_payloads": [
				{ "text": "Go to Wishlist", "type": "URL", "payload": "https://wishcart.com/wishlist" },
				{ "text": "Remove Items", "type": "QUICK_REPLY", "payload": "Remove Items" }
			]
		}
	]
	return (
		<div className='tw-text-sm tw-p-4 md:tw-grid tw-grid-cols-3 tw-flex-wrap tw-gap-2 tw-items-start'>
			{
				templates.map((template => {
					return <SelectWAPreview key={template.title} template={template} setNewTemplate={setNewTemplate} onClose={onClose} />
				}))
			}
		</div>
	)
}


const showCreateTemplatePopUp = (onTemplateSelected) => {
	const sample_template_popup = Popup.show("Select Template", <SampleTemplates setNewTemplate={(selected_sample_template) => {
		onTemplateSelected(selected_sample_template)
		sample_template_popup.close()
	}} onClose={() => { sample_template_popup.close() }} />)
};


export { getBodyVariables, getTemplateBody, NOTIFICATION_TYPES, showCreateBroadcastPopUp, showCreateTemplatePopUp, showSelectTemplatePopUp, STATUS_STYLE };

