import { faPencil, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { createStaticRanges, DateRange, DateRangePicker, defaultStaticRanges } from "react-date-range";

import { getByIds } from "base/get_by_ids";
import { DateView } from "base/ui/date";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// eslint-disable-next-line import/order
import { addDays } from "date-fns";

import { Popup } from "base/ui/popups";
import { useBroadcastedState } from "base/utils/events";

import { LOCAL_TZ_OFFSET_MILLIS, INT64_MAX, MILLIS_IN_A_DAY } from "../../base/constants";
import SukhibaLogo from "../../images/ic_sukhiba_logo_light.svg"
import { ReactComponent as SoftStar } from "../../images/landing/ic_ss_green.svg";
import { ReactComponent as MetaLogo } from "../../images/meta_with_bg_logo.svg";
import { ReactComponent as Crown } from "../../images/onboarding/ic_crown.svg";
import IMG_SUCCESS from "../../images/onboarding/ic_success.svg"
import IMG_SUPPORT from "../../images/onboarding/img_support_call.webp";
import { PagePricing } from "../../pages/landing/PricingPage";
import { MetaWhatsappConnectButton } from "../../pages/WaConnect";


function LastUpdatedUser({ className, user_at }) {
	const [user, setUser] = useState(null);
	useEffect(() => {
		if (!user_at) return;
		getByIds({ user_ids: [user_at.user_id] }).then((data) => {
			setUser(data.users[user_at.user_id]);
		});
	}, [user_at]);
	if (!user) return null;
	return (
		<div className={`tw-flex tw-gap-1 tw-text-xxs tw-text-gray-400 ${className}`}>
			<p>Last Updated by:</p>
			<p className="tw-text-black">{user.name}</p>
			<DateView millis={user_at.at} />
		</div>
	);
}

function EditUserById({ className, user_id, onEdit }) {
	const [user, setUser] = useState(null);
	useEffect(() => {
		if (!user_id) return;
		getByIds({ user_ids: [user_id] }).then((data) => {
			setUser(data.users[user_id]);
		});
	}, [user_id]);
	if (!user) return null;
	return (
		<div
			className={`tw-flex tw-gap-1 tw-items-center ${className}`}
			onClick={(e) => {
				e.stopPropagation();
				onEdit();
			}}
		>
			<p className="tw-text-black">{user.name}</p>
			<FontAwesomeIcon icon={faPencil} className="tw-mr-2" />
		</div>
	);
}

const UserBadge = ({ user }) => (
	<div key={user._id} className={"tw-flex tw-py-2 tw-px-4 tw-items-center"}>
		<div className="tw-w-10 tw-h-10 tw-rounded-full flex-box tw-text-secondary tw-bg-secondary-bg tw-overflow-hidden">
			{!user.image ? (
				<span>{user.name?.slice(0, 1)}</span>
			) : (
				<img alt={user.name} src={user.image} className="tw-object-fill" />
			)}
		</div>
		<div className="tw-grow tw-px-2">
			<div className="tw-text-gray-600 tw-text-sm tw-font-bold">{user.name}</div>
			<div className="tw-text-gray-500 tw-text-sm">{user.phone_number || user.email_id}</div>
		</div>
	</div>
);

const CircularProgressBar = ({ total = 100, current = 0, styles }) => {
	const { height = 100, width = 100, color = "#15966E", strokeWidth: customStrokeWidth, fontSize } = styles;

	const size = Math.min(height, width);
	const strokeWidth = customStrokeWidth || size * 0.1; // 10% of size for stroke width if not provided
	const radius = (size - strokeWidth) / 2;
	const circumference = 2 * Math.PI * radius;
	const progress = total > 0 ? (current / total) * 100 : 0;
	const strokeDashoffset = circumference - (progress / 100) * circumference;

	const dynamicFontSize = fontSize || "11px";

	return (
		<div style={{ width: `${width}px`, height: `${height}px` }} className="tw-relative">
			<svg className="tw-w-full tw-h-full" viewBox={`0 0 ${size} ${size}`}>
				<circle
					className="tw-text-gray-200 tw-stroke-current"
					strokeWidth={strokeWidth}
					cx={size / 2}
					cy={size / 2}
					r={radius}
					fill="transparent"
				/>
				<circle
					className="tw-stroke-current"
					style={{ color }}
					strokeWidth={strokeWidth}
					strokeLinecap="round"
					cx={size / 2}
					cy={size / 2}
					r={radius}
					fill="transparent"
					strokeDasharray={circumference}
					strokeDashoffset={strokeDashoffset}
					transform={`rotate(-90 ${size / 2} ${size / 2})`}
				/>
			</svg>
			<div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
				<span style={{ fontSize: dynamicFontSize }} className="tw-font-bold">
					{current}
				</span>
			</div>
		</div>
	);
};

const RangeDatePicker = ({ start_time, end_time, onChange, from_days, to_days = -1 }) => {
	const [date_range, setDateRange] = useState([
		{
			startDate: new Date(start_time + LOCAL_TZ_OFFSET_MILLIS),
			endDate: new Date(end_time + LOCAL_TZ_OFFSET_MILLIS),
			key: "selection",
		},
	]);

	// Custom static ranges
	const customStaticRanges = [...defaultStaticRanges.filter((range) => range.label !== "Today")];

	return (
		<div className="tw-grid tw-justify-center tw-items-center">
			<DateRangePicker
				rangeColors={["#204540"]}
				months={1}
				calendarFocus="backwards"
				direction="horizontal"
				minDate={addDays(new Date(), -from_days)}
				maxDate={addDays(new Date(), to_days)}
				ranges={date_range}
				onChange={(date_range) => {
					setDateRange([date_range.selection]);
				}}
				showSelectionPreview={true}
				moveRangeOnFirstSelection={false}
				preventSnapRefocus={true}
				editableDateInputs={true}
				startDatePlaceholder="Select Start Date"
				endDatePlaceholder="Select End Date"
				staticRanges={createStaticRanges(customStaticRanges)}
				inputRanges={[]}
			/>
			<div className="tw-flex tw-justify-end tw-p-4">
				<button
					className="tw-py-1 tw-px-4 tw-text-white tw-bg-[#15956E] tw-rounded"
					onClick={() => {
						onChange(date_range[0]);
					}}
					type="button"
				>
					Apply
				</button>
			</div>
		</div>
	);
};

// Renders only calender view without static ranges
const RangeDate = ({ start_time, end_time, onChange, from_days, to_days = -1 }) => {
	const [date_range, setDateRange] = useState([
		{
			startDate: new Date(start_time + LOCAL_TZ_OFFSET_MILLIS),
			endDate: new Date(end_time + LOCAL_TZ_OFFSET_MILLIS),
			key: "selection",
		},
	]);

	return (
		<div className="tw-grid tw-justify-center tw-items-center tw-pt-4">
			<DateRange
				rangeColors={["#204540"]}
				months={1}
				calendarFocus="backwards"
				direction="horizontal"
				minDate={addDays(new Date(), -from_days)}
				maxDate={addDays(new Date(), to_days)}
				ranges={date_range}
				onChange={(date_range) => {
					setDateRange([date_range.selection]);
				}}
				showSelectionPreview={true}
				moveRangeOnFirstSelection={false}
				preventSnapRefocus={true}
				editableDateInputs={true}
				startDatePlaceholder="Select Start Date"
				endDatePlaceholder="Select End Date"
			/>
			<div className="tw-flex tw-justify-end tw-p-4">
				<button
					className="tw-py-1 tw-px-4 tw-text-white tw-bg-[#15956E] tw-rounded"
					onClick={() => {onChange(date_range[0]);}}
					type="button"
				>
					Apply
				</button>
			</div>
		</div>
	);
};


function BillingPlanHeader(){

	/* BILLING PLAN UPGRADES */
	const [org] = useBroadcastedState("org");

	const billing_plan = org?.billing_plan;
	const is_trial_number = org?.wa_business_number.includes("-");
	const billing_plan_expires_at = billing_plan?.expires_at || INT64_MAX;
	const num_days_to_expiry = parseInt(Math.round(Math.floor(billing_plan_expires_at - new Date().getTime()) / MILLIS_IN_A_DAY), 10)

	useEffect(
		() => {
			let popup = null;
			if (num_days_to_expiry <= 0) {
				// upgrade blocker
				if (is_trial_number) {
					popup = Popup.blockUi(
						<div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-bg-white tw-border tw-rounded-lg tw-p-11 max-md:tw-p-5">
							<div className="tw-rounded-full tw-p-2">
								<MetaLogo className="tw-h-32 tw-w-32" />
							</div>
							<p className="tw-text-primary-text tw-text-[22px] tw-font-[700] tw-leading-6 max-md:tw-text-lg">
								Unlock the power of your official WhatsApp number—dive in{" "}
								<span className="tw-text-[#006EFF] tw-font-[700] tw-leading-6 "> free for 7 days </span>, and only pay
								once you&apos;re hooked!
							</p>
							<div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center tw-justify-center tw-mt-4">
								<MetaWhatsappConnectButton
									params={{ delivery_org_id: org._id }}
									className="tw-py-3 tw-px-3 tw-rounded-lg tw-text-md tw-font-semibold max-md:tw-py-2  max-md:tw-px-2  max-md:tw-text-sm tw-w-full"
								>
									Update Number
								</MetaWhatsappConnectButton>
								<SpeakToSpecialistButton org_id={org._id} selected_plan="Starter" className="btn-secondary !tw-m-0 tw-text-primary-v2 tw-bg-white !tw-rounded-lg" />
							</div>
						</div>,
					);
				} else if (org?.billing_plan?.TRIAL_7_DAYS) {
					// PLEASE UPGRADE OR CONTACT LINK
				}
			}
		}, [org]
	);

	if(num_days_to_expiry > 30 && !is_trial_number) return null; // no banner
	return (
		<div className="tw-bg-[#FFCD69] tw-p-2 tw-text-black md:tw-flex tw-justify-center tw-text-sm tw-font-semibold tw-gap-2 tw-items-center">
			<SoftStar className="tw-mr-2 tw-h-[21px] max-md:tw-hidden" />
			{
				is_trial_number 
				? 	(
						<>
							<p>
								{
									num_days_to_expiry > 0 
									? <>You have
										<span className="tw-text-red-600">
											{` ${num_days_to_expiry} ${num_days_to_expiry <= 1 ? "day" : "days"} `}
										</span>
										left to explore this SUKHIBA DEMO account. Connect your number to activate your own trial account.
									</>
									: <>{"Your free trial is "}
									<span className="tw-text-red-600">
										expired.
									</span>
									</>
								}
							</p>
							<MetaWhatsappConnectButton
								params={{ delivery_org_id: org._id }}
								className="tw-text-sm tw-py-1 !tw-my-0 tw-bg-[#005BD3]"
							>
								Update Number
							</MetaWhatsappConnectButton>
						</>
					) 
			: 	(
					num_days_to_expiry < 30
					?	<p>
							{"Your free trial will expire in "}
							<span className="tw-text-red-600">
								{`${num_days_to_expiry} ${num_days_to_expiry <= 1 ? "day" : "days"}`} .
							</span>
						</p>
					:	null
				)
			}
			<SoftStar className="tw-ml-2 tw-h-[21px] max-md:tw-hidden" />
		</div>
	);
}

const FeatureBlockView = () => {
	return <div className="tw-p-2 tw-text-black tw-grid tw-justify-items-center tw-text-[14px] max-md:tw-text-[12px] tw-font-medium tw-gap-2 ">
		<div className="tw-rounded-full tw-bg-[#FFCD69]/[0.13] tw-p-2">
			<Crown className="tw-h-[56px] tw-w-[56px]" />
		</div>
		<p className="tw-font-bold tw-text-sm">
			To explore this feature you need to be on Paid Plan
		</p>
		<p className="tw-text-[#64748B]">
			Unlock exclusive benefits and opportunities with Sukhiba membership.
		</p>
		<button className="btn-primary-v2 !tw-bg-[#F3B518] !tw-border-[#F3B518]" type="button">
			View Plans
		</button>
	</div>
}

const SpeakToSpecialistButton = ({title, org_id, selected_plan, className}) =>{
	const [is_loading, setIsLoading] = useState()

	function handlePlanRequest() {
		if (is_loading) return;
		setIsLoading(true);
		axios
			.post(`/api/admin/dorg/${org_id}?action=speak_to_specialist`, {
				selected_plan
			})
			.then((resp) => {
				if (resp.data.requested) {
					const popup = Popup.show("", <div className="tw-p-4 tw-grid tw-justify-center tw-text-center tw-justify-items-center tw-gap-1">
						<img src={IMG_SUCCESS} alt="" />
						<div className="tw-font-bold tw-text-md">Your Request Sent Successfully</div>
						<div className="tw-text-sm tw-text-gray-400">Our Support Team will get back to you shortly</div>
						<div className="tw-mt-2">
							<button className="btn-primary-v2"
								type="button"
								onClick={() => {
									popup.close()
								}}
							>OK</button>
						</div>
					</div>, { className: "tw-max-w-[600px]" })
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	return (
		<button
			className={`btn-primary-v2 max-md:tw-flex-1 tw-w-full tw-rounded-full tw-my-4 tw-py-3.5 ${className}`}
			type="button"
			onClick={handlePlanRequest}
		>
			{
				is_loading ? <FontAwesomeIcon icon={faSpinner} className="tw-mr-2 tw-animate-spin" /> : null
			}
			{title || "Speak to specialist"}
		</button>
	)
}

const PlansNPricingView = ({org}) => {
	return <div className="tw-p-4 tw-text-black">		
		<h4 style={{ fontWeight: "bold", color: "#000000" }}>Plans & Pricing</h4>
		<p className="tw-text-xs tw-text-secondary-text">
			Let’s get started with onboarded, different price points, calendar to set up onboarding call with specialist
		</p>

		<div className='tw-flex tw-mt-4 tw-items-center tw-gap-4 tw-rounded-xl tw-p-4 tw-border tw-border-[#F3B518] tw-bg-[#FFFCF6] tw-justify-between tw-flex-wrap'>
			<div className="tw-flex tw-flex-row md:tw-gap-x-4 tw-items-center md:tw-w-[70%]">
				<div className="tw-rounded-full">
					<img src={IMG_SUPPORT} className="tw-h-[60px] tw-w-[65px] max-lg:tw-hidden" alt="img_support" />
				</div>
				<div>
					<p className="tw-text-md tw-font-semibold tw-text-primary-text">Set up onboarding call with specialist</p>
					<p className="tw-text-[13px] tw-text-secondary-text tw-font-normal ">
						Our dedicated relationship manager will help you get started with the platform as well as help in finding right plan customized based on your needs. Start your journey today
					</p>
				</div>
			</div>
			<button
				className="btn-primary-v2"
				onClick={() => {
					Popup.show("Schedule a Call", <div className="tw-p-4">
						<HubSpotMeetingView />
					</div>)
				}}
				type="button"
			>
				Schedule a Call
			</button>
		</div>
		<PagePricing className="tw-pt-1" SpeakToSpecialistButton={SpeakToSpecialistButton} org_id={org._id} />		
	</div>
}

const HubSpotMeetingView = () => {
	useEffect(() => {
	  const script = document.createElement('script');
  
	  script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
	  script.async = true;
  
	  document.body.appendChild(script);
  
	  return () => {
		document.body.removeChild(script);
	  }
	}, []);
  
	return <div className="tw-w-full">
	  	<div className="meetings-iframe-container" 
	  		data-src="https://meetings-eu1.hubspot.com/meetings/book-a-meeting-with-me/clone?embed=true"
			async={true}
		/>
	</div>
}


const SukhibaSideFrame = ({ children, zIndex, is_create_shop, shop_info }) => {
	return (
		<div className="tw-flex tw-min-h-full tw-justify-center tw-w-full tw-items-center">
			<div className='tw-bg-white tw-transform tw-transition-all md:tw-rounded-2xl tw-shadow-xl max-md:tw-w-full tw-min-w-[55%] 2xl:tw-min-w-[40%]'>
				<div className='tw-flex tw-h-full max-md:tw-flex-col'>
					<div className='tw-relative tw-bg-[#052F2F] tw-px-8 md:tw-pt-20 tw-flex md:tw-flex-col md:tw-justify-end md:tw-w-[45%]'>
						<div className='max-md:tw-pt-8 tw-z-10'>
							<img src={SukhibaLogo} alt="logo" />
							<div className='max-md:tw-my-4 md:tw-my-8 tw-text-white tw-text-[40px] font-inter tw-whitespace-pre !tw-font-semibold'>
								{
									is_create_shop
										? <span>Let&apos;s set-up <br /><span className='tw-text-yellow-500'>your shop!</span></span>
										: <span>
											Welcome to <br />
											<span className='tw-text-yellow-500'>{shop_info?.title || "Sukhiba"}
											</span>
											<p className='tw-text-md tw-font-normal'>AI-powered Conversational <br />
											<span>Commerce Built on WhatsApp</span></p>
										</span>
								}
							</div>
						</div>
						<img className='max-md:tw-absolute max-md:tw-bottom-0 max-md:tw-opacity-[9%] max-md:tw-right-0' 
							src={'https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2023-08-05/1691220919wzUbJ4a2RJ__Logingrouppic.png'} 
							alt="logo"
						/>
					</div>
					<div className='tw-px-8 tw-py-4 tw-flex-1'>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
}


export { 
	EditUserById, UserBadge, LastUpdatedUser, CircularProgressBar, RangeDatePicker,
	RangeDate, BillingPlanHeader, 
	FeatureBlockView, PlansNPricingView, HubSpotMeetingView, SukhibaSideFrame
};
