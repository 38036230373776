import React, { useRef } from 'react'
import { getDisplayPrice } from '../../../../helpers/StoreHelper';

const RULES_KEYS = Object.freeze({
    // types
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    DROPDOWN: 'dropdown',
    IMAGE: 'image',
    TEXT: 'textarea',
    NUMBER: 'number',
    // props
    MAX_SELECTIONS: 'max_selections',
    DISABLED: 'disabled',
    SELECTED: 'selected',
    DEFAULT: 'default'
})

const DEFAULT_RULE = [
    [
        [
            "type",
            "radio"
        ],
        [
            "prop",
            "max_selections",
            "1"
        ]
    ]
]
function getSubSelectionType (rules) {
    for (let rule of rules) {
        const type_rule = rule.find(r => r[0] === 'type');
        if (type_rule) {
            return type_rule;
        }
    };
}


function getProps (rules) {
    const props = {};
    if (Array.isArray(rules)) {
        for (let rule of rules) {
            if (Array.isArray(rule)) {
                for (let r of rule) {
                    switch (r[1]) {
                        case RULES_KEYS.DISABLED:
                            props.disabled = true;
                            break;
                        case RULES_KEYS.SELECTED:
                            props.selected = true;
                            break;
                        case RULES_KEYS.DEFAULT:
                            props.default = r[2];
                            break;
                        case RULES_KEYS.MAX_SELECTIONS:
                            props.max_selections = r[2];
                            break;
                    }
                }
            }
        }
    }
    return props;
}

const SubSelectionOption = ({sub_selection, type, ctx, max_selections, group_name, delivery_route}) => {
    const {title, description, rules, price} = sub_selection;
    const {disabled, selected} = getProps(rules);
    const max_select = Number(max_selections);

    if (ctx[title] === undefined) ctx[title] = selected || false

    const onChange = (e) => {
        if (type === RULES_KEYS.RADIO) {
            Object.keys(ctx).forEach(key => ctx[key] = false);
            ctx[title] = true;
        } else {
            if (!e.target.checked || !max_select || Object.values(ctx).filter(val => !!val)?.length < Number(max_selections)) {
                ctx[title] = e.target.checked;
            } else {
                e.preventDefault();
            }
        }
    }

    return (
        <div className={`hflex tw-gap-4 tw-mb-3 last:tw-mb-0 ${disabled ? 'tw-opacity-50' : ''}`}>
            <label className='tw-grow' htmlFor={title}>
                <p className='tw-text-sm tw-mb-[2px]'>{title}</p>
                {description ? <p className='tw-text-xs tw-text-gray-500'>({description})</p> : null}
            </label>
            <div className='tw-flex tw-items-center tw-gap-2'>
                {price ? <span className='tw-font-bold tw-text-xs'>+ {getDisplayPrice(delivery_route.currency, price)}</span> : null}
                <input name={group_name} id={title} type={type} 
                    defaultChecked={ctx[title]} onClick={onChange} 
                    disabled={disabled} 
                />
            </div>
        </div>
    )
}

const SubSelection = ({sub_selection, ctx, index, delivery_route}) => {
    const {title, description: _desc, rules, sub_selections} = sub_selection;
    const type_rule = getSubSelectionType(rules || DEFAULT_RULE);
    const type = type_rule?.[1];
    const {max_selections} = getProps(rules);
    const description = _desc || (max_selections ? `Select upto ${max_selections} option(s)` : null);
    if (!ctx[title]) ctx[title] = {sub_selections: {}};

    return (
        <div className='tw-rounded-lg tw-bg-white tw-mb-4'>
            <div className='tw-px-4 tw-py-2 tw-border-b'>
                <p className='tw-font-bold tw-mb-1'>{title}</p>
                <p className='tw-text-xs tw-text-gray-500'>{description}</p>
            </div>
            <div className='tw-px-4 tw-py-4'>
                {
                    type === RULES_KEYS.CHECKBOX
                        ?   sub_selections?.map(s => <SubSelectionOption key={s.title} sub_selection={s} type="checkbox" ctx={ctx[title].sub_selections} max_selections={max_selections} delivery_route={delivery_route}  />)
                        :   type === RULES_KEYS.RADIO 
                            ?   sub_selections?.map((s) => <SubSelectionOption key={s.title} group_name={`sub_${index}`} sub_selection={s} type="radio" ctx={ctx[title].sub_selections} delivery_route={delivery_route} />)
                            :   null
                }
            </div>
        </div>
    )
}

const ProductSubSelections = ({sub_selections, onAdd, onUpdate, delivery_route, config}) => {
    const ctx = useRef(config ? structuredClone(config) : {sub_selections: {}}).current;

    const addSubSelectionsConfig = () => {
        config ? onUpdate(ctx) : onAdd(ctx);
    }
    return (
        <div className='tw-pb-10'>
            <div className='tw-bg-gray-bg tw-p-4'>
                {sub_selections?.map((s, i) => <SubSelection index={i} key={s.title} sub_selection={s} ctx={ctx.sub_selections} delivery_route={delivery_route} />)}
            </div>
            <div className='tw-absolute tw-w-full tw-bottom-0 tw-bg-white tw-border-t tw-px-4 tw-py-2'>
                <button className='tw-bg-secondary-lighter tw-w-full tw-text-white tw-text-sm tw-rounded tw-px-4 tw-py-2' onClick={addSubSelectionsConfig}>
                    {config ? 'Update' : 'Add'}
                </button>
            </div>
        </div>
        
    )
}

export default ProductSubSelections